* {
  background: none;
  border: none;
  box-sizing: border-box;
  color: inherit;
  font: inherit;
  line-height: 1.125;
  margin: 0;
  padding: 0;
}

:focus {
  outline: solid 1px white;
}

html {
  font-size: 18px;
}

body {
  background: black;
  color: #aaa;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  min-height: 100vh;
  overflow: hidden auto;
  padding: 1rem;
  width: 100vw;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  a {
    font-weight: bold;

    &:focus,
    &:hover {
      color: white;
    }

    &.icon {
      background: black;
      text-decoration: none;
    }

    &.circle {
      border-radius: 100%;
    }
  }

  h1,
  p {
    margin-bottom: 1rem;
  }

  h1 {
    font-weight: bold;
    font-size: 3rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  em {
    font-style: italic;
  }

  header {
    background: #111;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .5rem;

    > a {
      word-spacing: -5px;
    }

    a {
      padding: .5rem;
      text-decoration: none;
    }

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }
}